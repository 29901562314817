import React, { useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { graphql } from 'gatsby'
import Filters from '../components/filters'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import Message from '../components/message'
//import MemberCard from '../components/memberCard'
import styles from '../styles/network.module.css'
import stylesCard from '../styles/memberCard.module.css'
import IconNetwork from '../assets/icon-network.svg'
import PublicationList from "../components/publicationList"

const Awardees = ({ data }) => {

    const {allAirtableAwardees:awardee} = data
    const [currentTag] = useQueryParam('tag', StringParam)
    const [scroll, setScroll] = useState(0)
    const [search, setSearch] = useState('')
    const tags = [...new Set(awardee.nodes.map(resource => resource.data.category ?? []).flat())].sort()

    const filteredNetwork = awardee.nodes
        .filter(member => currentTag === undefined || member.data.category?.includes(currentTag))
        .filter(member => search === '' || member.data.category.toLowerCase().includes(search.toLocaleLowerCase()) || (member.data.Name?.[0].data.Name ?? '').toLowerCase().includes(search.toLocaleLowerCase()))



    useEffect(() => {
        window.scrollTo(0, scroll)
	})

    return (
		<Layout>
            <div className={styles.header}>
                <h1>Publications</h1>
                {/*<PromptButton primary={true} handleClick={handleMaterialRequest}>Contact experts</PromptButton>*/}
            </div>
            <Filters path={`/projects`} tags={tags} currentTag={currentTag} search={search} handleClick={() => setScroll(window.scrollY)} handleSearch={value => setSearch(value)}/>
            {
                filteredNetwork.length > 0? (
                    <div className={styles.network}>
                        {
                            filteredNetwork.map((award, i) => {
                                const {data} = award

                                return (
                                  <div className={stylesCard.memberCard} key={i}>
                                    <div className={stylesCard.content}>
                                      <div>{data.Name}</div>
                                      <div>{data.category}</div>
                                      { data.People &&
                                        data?.People.map((person,i) => {
                                          const {data} =  person
                                          const hasPubs = data?.RadXPublication && data.RadXPublication.length > 0
                                          const hasDisplayname = data?.display_name && data.display_name !==""
                                          return ( <div>

                                              { hasDisplayname && (<div>{data.display_name}</div>) }

                                              { hasPubs && (<PublicationList publications={data.RadXPublication} max={3} readMore={`/weatherReport/${data.Name}`}>{data.Title}</PublicationList>)
                                              }
                                              </div>
                                            )

                                        })
                                      }
                                    </div>
                                  </div>
                                    // <MemberCard key={data.Slug} name={data.Display_name} picture={data.Picture?.[0].url} institution={data.Institution?.[0].data.Name} role={data.Role} linkedIn={data.LinkedIn} website={data.Website} expertise={data.Expertise} currentTag={currentTag} publications={data.Publications ?? undefined} slug={data.Slug}/>
                                )
                            })
                        }
                    </div>
                ) : (
                    <Message Icon={IconNetwork} content={'Award not found'}/>
                )
            }
		</Layout>
	)
}

export const query = graphql`
    query  {
  allAirtableAwardees {
    nodes {
      data {
        People {
          ... on AirtablePeople {
            id
            data {
              display_name
              RadXPublication {
                data {
                  Abstract
                  Author
                  Date
                  HideFromNetwork
                  Journal
                  Name
                  Last_Modified
                  Topics
                  keywords
                  Title
                  Source
                  Source_type
                }
              }
            }
          }
        }
        Name
        NIH_Project_Reporter
        category
        project_title
        pi_organization  {
            ... on  AirtableInstitutions {
                id
                data {
                    Name
                }
            }
        }
        grant_number
      }
    }
  }
}
`

export default Awardees
